import React from "react"
import Layout from "../components/Layout"

const impressum = () => {
  return (
    <Layout>
      <article>
        <div className="page">
          <h1>Impressum</h1>
          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>
            Sweet Data Development UG (haftungsbeschr&auml;nkt)
            <br />
            Tuchollaplatz 2<br />
            10317 Berlin
          </p>

          <p>
            Handelsregister: HRB 235031 B<br />
            Registergericht: Amtsgericht Charlottenburg
          </p>

          <p>
            <strong>Vertreten durch:</strong>
            <br />
            Diana M&ouml;ckl
          </p>

          <h2>Kontakt</h2>
          <p>
            Telefon: +49 (0)30 32506927‬
            <br />
            Telefax: +49 (0)30 32506928‬
            <br />
            E-Mail: info@sweetdatadevelopment.de
          </p>

          <h2>Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
            Umsatzsteuergesetz:
            <br />
            DE349105649
          </p>

          <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
          <p>
            <strong>Name und Sitz des Versicherers:</strong>
            <br />
            Berufshaftplicht GMBD und CO KG
            <br />
            Strassenname und NR 4<br />
            12345 Stadtname
          </p>
          <p>
            <strong>Geltungsraum der Versicherung:</strong>
            <br />
            Weltweit
          </p>

          <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h2>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p>
            Wir nehmen an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die
            Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V.,
            Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (
            <a
              href="https://www.verbraucher-schlichter.de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://www.verbraucher-schlichter.de
            </a>
            ).
          </p>

          <p>
            Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
          </p>
        </div>
      </article>
    </Layout>
  )
}

export default impressum
